(function ($) {

    $.fn.updateImageDialog = function (options) {
        var self = $(this);

        var settings = $.extend({
            HiddenValueSelector: "#ImageURL",
            HiddenCropToTopSelector: "",
            ImageFieldSelector: ".edit-form-image",
            SuccessHandler: undefined,
            S3RefreshURL: "",
            CropCurrentImage: true,
            CropType: "entropy",
            TargetImageFieldWidth: 100,
            TargetImageFieldHeight: 100,
            ModalImageFieldWidth: 100,
            ModalImageFieldHeight: 100,
            IncludeUnsplash: true,
            HelpTipMessage: "",
            ShowCropToFitCheckbox: false,
            CropToFitHoverText: "",
            ValidFileTypes: undefined
        }, options);
        
        var unsplashClientId = "65f64eeaa6d3a0b53537d09fd977ac2bb484db2d8065e9c9c4ffa6617bc82e1b";

        var $grid = null;

        self.uploadAttemptsCount = 0;

        function UploadSuccess(data, textStatus, xhr) {

            self.find("#CurrentImage").attr("src", YouLi.Common.Utils.AddParametersToURL("https://ylt-images.imgix.net/" + encodeURIComponent(YouLi.Common.S3FileUpload.NewFileKey), "fit=crop&crop=" + settings.CropType + (settings.ModalImageFieldHeight == "auto" ? "" : "&h=" + settings.ModalImageFieldHeight) + "&w=" + settings.ModalImageFieldWidth));

            self.find(".thumbnail-image-selectable.selected").removeClass("selected");
            self.find("#CurrentImage").addClass("selected").removeClass("hidden").attr("data-imageurl", encodeURIComponent(YouLi.Common.S3FileUpload.NewFileKey));

            self.find("#file").val(""); /* clear file selection to allow the same file to be selected again and trigger upload */

            // Nb: loading button will be cleared when new image is loaded
        }

        function UploadError(xhr, ajaxOptions, thrownError) {

            if (xhr.status == 403 && self.uploadAttemptsCount == 0) {
                // forbidden - try to refresh policy
                $.ajax({
                    url: settings.S3RefreshURL,
                    type: "GET",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    complete: function (response) {
                        if (response.status != 200 || (response.responseJSON != undefined && response.responseJSON.success == false)) {
                            YouLi.Common.UI.ShowStandardDialog("An error occurred. Please refresh the page to try again.");
                        }
                        else {
                            YouLi.Common.S3FileUpload.KeyPrefix = response.responseJSON.newKeyPrefix + response.responseJSON.newUUID;
                            YouLi.Common.S3FileUpload.Uuid = response.responseJSON.newUUID;
                            YouLi.Common.S3FileUpload.Policy = response.responseJSON.policy;
                            YouLi.Common.S3FileUpload.PolicySignature = response.responseJSON.policySignature;

                            // retry upload
                            self.uploadAttemptsCount++;
                            YouLi.Common.S3FileUpload.SingleFileSelected(document.querySelector(".edit-image-modal input[type='file']"), settings.ValidFileTypes, UploadSuccess, UploadError, UploadComplete);
                        }
                    }

                });
            }
            else {
                alert("There was an error attempting to upload the file. (" + thrownError + ")");
                self.uploadAttemptsCount++;
                self.find("#file").val(""); /* clear file selection to allow the same file to be selected again and trigger upload */
                YouLi.Common.UI.HideButtonLoading(self.find(".btn-done"));
            }
        }

        function UploadComplete(data, textStatus, xhr) {
        }

        function UpdateImagesFromUnsplash(searchTerm) {

            var url = "";

            self.find("#UnsplashImages").hide();
            self.find("#UnsplashImagesNone").hide();
            self.find("#UnsplashImagesLoader").show();

            if (searchTerm === undefined) {
                // get the YouLiveToTravel user's images
                $.get("https://api.unsplash.com/collections/1347059/photos?client_id=" + unsplashClientId + "&page=1&per_page=20", function (response, textStatus, XMLHttpRequest) {
                    var list = self.find("#UnsplashImages");

                    self.find("#UnsplashImages .grid-item").each(function () {
                        $grid.masonry('remove', this);
                    });

                    self.find("#UnsplashImagesLoader").fadeOut(function () {

                        if (response.length > 0) {
                            self.find("#UnsplashImages").show();

                            $.each(response, function () {
                                AppendUnsplashImage(this, list);
                            });

                            $grid.imagesLoaded().progress(function () {
                                $grid.masonry('layout');
                            });
                        }
                        else {
                            self.find("#UnsplashImagesNone").fadeIn();
                        }
                    });

                });
            }
            else {
                // Search using search term
                $.get("https://api.unsplash.com/search/photos?client_id=" + unsplashClientId + "&page=1&per_page=20&query=" + searchTerm, function (response, textStatus, XMLHttpRequest) {
                    var list = self.find("#UnsplashImages");

                    self.find("#UnsplashImages .grid-item").each(function () {
                        $grid.masonry('remove', this);
                    });

                    self.find("#UnsplashImagesLoader").fadeOut(function () {

                        if (response.results.length > 0) {
                            self.find("#UnsplashImages").show();

                            $.each(response.results, function () {
                                AppendUnsplashImage(this, list);
                            });

                            $grid.imagesLoaded().progress(function () {
                                $grid.masonry('layout');
                            });
                        }
                        else {
                            self.find("#UnsplashImagesNone").fadeIn();
                        }

                    });

                });
            }

        }

        function AppendUnsplashImage(image, target) {
            newImageContainer = $('<div>', {
                class: "grid-item-image-container"
            });

            newImage = $('<img/>', {
                src: image.urls.thumb,
                class: "img-responsive thumbnail-image-selectable",
            });
            newImage.attr("data-imageurl", image.urls.raw);
            newImage.attr("data-source", "unsplash");
            newImage.attr("data-download-link", image.links.download_location + "?client_id=" + unsplashClientId);

            newGridItem = $('<div/>', {
                class: 'grid-item col-xs-6 col-sm-4',
            });
            newImage.appendTo(newImageContainer);

            newAttribution = $('<div>', {
                class: "unsplash-attribution hidden-xs",
            });
            newAttribution.html("<div class='text'><a href='" + image.user.links.html + "?utm_source=YouLi&utm_medium=referral&utm_campaign=api-credit' target='_blank'><strong>" + image.user.name + "</strong></a></div>");
            newAttribution.appendTo(newImageContainer);

            newImageContainer.appendTo(newGridItem);

            target.append(newGridItem).masonry('appended', newGridItem);
        }


        function SelectionDone() {
                       
            var selectedImageURL = self.find("img.selected").attr("data-imageurl");
            var selectedImageDownloadLocation = self.find("img.selected").attr("data-download-link");
            $(settings.HiddenValueSelector).val(selectedImageURL).trigger("change");
            var existingImageNewURL = selectedImageURL;

            var isFullImageURL = self.find(".thumbnail-image-selectable.selected").attr("data-source") == "unsplash" || selectedImageURL.startsWith("http");
            if (!isFullImageURL) {
                existingImageNewURL = "https://ylt-images.imgix.net/" + selectedImageURL;
            }

            if (settings.ShowCropToFitCheckbox) {
                var cropToFit = self.find("#ImageCheckboxCropToFit").is(":checked");
                $(settings.HiddenCropToTopSelector).val(cropToFit);
            }

            RefreshImagePreview(settings.ImageFieldSelector, settings.CropCurrentImage, false);

            // prepare for next time the dialog gets loaded
            self.find(".existing-image").attr("src", YouLi.Common.Utils.AddParametersToURL(existingImageNewURL, "fit=crop&crop=" + settings.CropType + (settings.ModalImageFieldHeight == "auto" ? "" : "&h=" + settings.ModalImageFieldHeight) + "&w=" + settings.ModalImageFieldWidth));
            self.find(".existing-image").attr("data-imageurl", existingImageNewURL);

            // Trigger Unsplash download location
            $.get(selectedImageDownloadLocation);

            if (settings.SuccessHandler !== undefined) {
                settings.SuccessHandler();
            }

            // YOULI-1776 if the following modal hide is done before the above success handler call, the removal of the modal-open class sometimes isn't triggered (the modal backdrop callback function isn't triggered), so let's just
            // do it after that line.  The reason appears to be because the success handler for avatar saving shows and hides the backdrop in itself.
            self.modal("hide");
        }

        var lastSearchTerm = "";

        function RefreshImagePreview(previewImageSelector, cropTargetPreview, addWithCrop) {

            //This function will refresh image preview either inside the popup or the target image outside the popup based on parameters passed
            var selectedImageURL = self.find("img.selected").attr("data-imageurl");

            var targetSelectedImage = selectedImageURL;

            var isFullImageURL = self.find(".thumbnail-image-selectable.selected").attr("data-source") == "unsplash" || selectedImageURL.startsWith("http");
            
            if (!isFullImageURL) {
                if (cropTargetPreview) {
                    targetSelectedImage = YouLi.Common.Utils.AddParametersToURL("https://ylt-images.imgix.net/" + selectedImageURL, "fit=crop&crop=" + settings.CropType + (settings.TargetImageFieldHeight == "auto" ? "" : "&h=" + settings.TargetImageFieldHeight + "&w=" + settings.TargetImageFieldWidth));
                }
                else {
                    targetSelectedImage = "https://ylt-images.imgix.net/" + selectedImageURL;
                    if (addWithCrop) { // will add width crop even if the crop to fit is not checked for the image embeded in popup
                        targetSelectedImage = YouLi.Common.Utils.AddParametersToURL("https://ylt-images.imgix.net/" + selectedImageURL, "fit=crop&crop=" + settings.CropType + "&w=" + settings.TargetImageFieldWidth);
                    }
                }
            }

            else {
                if (cropTargetPreview) {
                    targetSelectedImage = YouLi.Common.Utils.AddParametersToURL(targetSelectedImage, "ixlib=rb-0.3.5&q=80&fm=jpg&crop=" + settings.CropType + "&cs=tinysrgb" + (settings.TargetImageFieldHeight == "auto" ? "" : "&h=" + settings.TargetImageFieldHeight + "&w=" + settings.TargetImageFieldWidth + "&fit=crop"));
                }
                else {
                    targetSelectedImage = YouLi.Common.Utils.AddParametersToURL(targetSelectedImage, "ixlib=rb-0.3.5&q=80&fm=jpg&crop=" + settings.CropType + "&cs=tinysrgb&w=1080&fit=max");  // reduce down the size for display
                    if (addWithCrop) {
                        targetSelectedImage = YouLi.Common.Utils.AddParametersToURL(targetSelectedImage, "ixlib=rb-0.3.5&q=80&fm=jpg&crop=" + settings.CropType + "&cs=tinysrgb&w=1080&fit=max" + "&w=" + settings.TargetImageFieldWidth);
                    }
                }
            }

            $(previewImageSelector).attr("src", targetSelectedImage);
         }

        function RefreshImagePreviewInsidePopup() {
            if (settings.ShowCropToFitCheckbox) {
                var cropToFit = self.find("#ImageCheckboxCropToFit").is(":checked");
                RefreshImagePreview(self.find(".existing-image"), cropToFit, true);
            }
        }

        $(function () {
            YouLi.Common.UI.Init();

            if (settings.ShowCropToFitCheckbox) {
                //$('.update-image-crop-check').append("<div class='control--checkbox'><label class='control'>Crop to Fit? <input checked='checked' id='ImageCheckboxCropToFit' name='CropToFit' type='checkbox' value='true'><input name='CropToFit' type='hidden' value='false'><div class='control__indicator'></div></label></div>")
                self.find('.update-image-crop-check').removeClass('hidden')
            } else {
                //$('.update-image-crop-check').remove('.control--checkbox')
                self.find('.update-image-crop-check').addClass('hidden')
            }

            if (!settings.IncludeUnsplash) {
                self.find("#SearchPhotos").addClass("hidden");
            }

            if (settings.HelpTipMessage !== "") {
                self.find("#UploadImageHelpText").removeClass("hidden").find(".helper-box-text").text(settings.HelpTipMessage);
            }

            // Initialise size of current image
            self.find(".edit-image-modal .thumbnail-image").css({
                "width": settings.ModalImageFieldWidth,
                "height": settings.ModalImageFieldHeight
            });

            self.off("show.bs.modal").on("show.bs.modal", function () {
                self.find(".thumbnail-image-selectable.selected").removeClass("selected");
                self.find(".existing-image").addClass("selected"); // initially select existing image
                self.find("#edit-image-modal-content").cslide();

                self.find("#SearchTerm").val("");

                self.find("#CurrentImage").off("load").on("load", function () {
                    $(this).removeClass("hidden");
                    YouLi.Common.UI.HideButtonLoading(self.find(".btn-done"));
                });

                $grid = self.find('.grid').masonry({
                    itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
                    columnWidth: '.grid-sizer',
                    percentPosition: true
                });

                UpdateImagesFromUnsplash();
                self.find('.grid').masonry('layout');

                lastSearchTerm = "";

            });

            // YOULI-1776 the removal of the modal-open class isn't being triggered sometimes (the modal backdrop callback function isn't triggered), so let's just
            // clear it here.
            self.off("hide.bs.modal").on("hide.bs.modal", function () {
                $("body").removeClass('modal-open');
            });

            self.off('shown.bs.modal').on('shown.bs.modal', function () {
                if ($(settings.HiddenCropToTopSelector).val()) {
                    self.find("#ImageCheckboxCropToFit").prop('checked', $(settings.HiddenCropToTopSelector).val().toLowerCase() === 'true');
                    RefreshImagePreviewInsidePopup();
                }
            });

            self.off("change", ".edit-image-modal input[type='file']").on("change", ".edit-image-modal input[type='file']", function (e) {
                if (this.files.length >= 1) {
                    YouLi.Common.UI.ShowButtonLoading(self.find(".btn-done"));

                    // Get a fresh S3 policy every time (so a user can upload the same file more than once, e.g. after changing it on their computer)
                    $.ajax({
                        url: settings.S3RefreshURL,
                        type: "GET",
                        dataType: "json",
                        contentType: "application/json; charset=utf-8",
                        complete: function (response) {
                            if (response.status != 200 || (response.responseJSON != undefined && response.responseJSON.success == false)) {
                                YouLi.Common.UI.ShowStandardDialog("An error occurred. Please refresh the page to try again.");
                            }
                            else {
                                YouLi.Common.S3FileUpload.KeyPrefix = response.responseJSON.newKeyPrefix + response.responseJSON.newUUID;
                                YouLi.Common.S3FileUpload.Uuid = response.responseJSON.newUUID;
                                YouLi.Common.S3FileUpload.Policy = response.responseJSON.policy;
                                YouLi.Common.S3FileUpload.PolicySignature = response.responseJSON.policySignature;

                                self.uploadAttemptsCount = 0;

                                if (!YouLi.Common.S3FileUpload.SingleFileSelected(e.target, settings.ValidFileTypes, UploadSuccess, UploadError, UploadComplete)) {
                                    YouLi.Common.UI.HideButtonLoading(self.find(".btn-done"));
                                }
                            }
                        }

                    });
                }
            });

            self.off("blur", "#SearchTerm").on("blur", "#SearchTerm", function () {

                // run search IF it hasn't already been run for this search term
                if (lastSearchTerm != $(this).val()) {
                    UpdateImagesFromUnsplash($(this).val());
                    lastSearchTerm = $(this).val();
                }
            });

            self.off("keyup", "#SearchTerm").on("keyup", "#SearchTerm", function (event) {
                if (event.keyCode == 13) {
                    // run search IF it hasn't already been run for this search term
                    if (lastSearchTerm != $(this).val()) {
                        UpdateImagesFromUnsplash($(this).val());
                        lastSearchTerm = $(this).val();
                    }
                }
            });

            self.off("click", ".thumbnail-image-selectable").on("click", ".thumbnail-image-selectable", function () {
                self.find(".thumbnail-image-selectable.selected").removeClass("selected")
                $(this).addClass("selected");
            });

            self.off("click", ".edit-image-modal-footer .btn-done").on("click", ".edit-image-modal-footer .btn-done", function () {
                SelectionDone();
            });

            self.off("dblclick", ".edit-image-modal .thumbnail-image-selectable").on("dblclick", ".edit-image-modal .thumbnail-image-selectable", function () {
                SelectionDone();
            });

            self.off("change", "#ImageCheckboxCropToFit").on("change", "#ImageCheckboxCropToFit", function () {
                RefreshImagePreviewInsidePopup();
            });

            if (settings.CropToFitHoverText) {
                $(".update-image-crop-check")
                    .attr("data-toggle", "popover")
                    .attr("data-container", "body")
                    .attr("data-placement", "bottom")
                    .attr("data-trigger", "hover")
                    .attr("data-content", settings.CropToFitHoverText)
                    .popover();
            }
        });

        return this;
    };

}(jQuery));