var YouLi = YouLi ? YouLi : {};
YouLi.Common = YouLi.Common ? YouLi.Common : {};
YouLi.Common.S3FileUpload = YouLi.Common.S3FileUpload ? YouLi.Common.S3FileUpload : {};

(function (YouLi) {
    (function (Common) {
        (function (S3FileUpload) {

            YouLi.Common.S3FileUpload.UploadUrl = "";
            YouLi.Common.S3FileUpload.SuccessURL = "";
            YouLi.Common.S3FileUpload.KeyPrefix = "";
            YouLi.Common.S3FileUpload.ACL = "";
            YouLi.Common.S3FileUpload.Uuid = "";
            YouLi.Common.S3FileUpload.AccessKey = "";
            YouLi.Common.S3FileUpload.Policy = "";
            YouLi.Common.S3FileUpload.PolicySignature = "";
            YouLi.Common.S3FileUpload.NewFileKey = "";

            // defaults
            YouLi.Common.S3FileUpload.FileTypeDescription = "image";
            YouLi.Common.S3FileUpload.MaxFileSize = 8000000;
            YouLi.Common.S3FileUpload.ValidFileTypes = ["image/gif", "image/jpeg", "image/png", "image/ico", "image/x-icon"];

            YouLi.Common.S3FileUpload.SingleFileSelected = function (element, validFileTypes, overrideSuccessHandler, overrideErrorHandler, overrideCompleteHandler, overrideProgressHandler, listElement) {
                
                var selectedFile = element.files[0];

                if (!validFileTypes) {
                    validFileTypes = YouLi.Common.S3FileUpload.ValidFileTypes;  // use default if not provided
                }

                if (selectedFile) {
                    
                    if (selectedFile.size > YouLi.Common.S3FileUpload.MaxFileSize)
                    {
                        alert("File must be max " + (YouLi.Common.S3FileUpload.MaxFileSize / 1000000) + "MB in size");
                        return false;
                    }
                    else if (!YouLi.Validation.IsValidFileType(selectedFile, validFileTypes))
                    {
                        alert("File must be a valid " + YouLi.Common.S3FileUpload.FileTypeDescription);
                        return false;
                    }

                    UploadFile(element, overrideSuccessHandler, overrideErrorHandler, overrideCompleteHandler, overrideProgressHandler, listElement);
                }

                return true;
            }

            function UploadFile(element, overrideSuccessHandler, overrideErrorHandler, overrideCompleteHandler, overrideProgressHandler, listElement) {

                var redirect = "";

                if (typeof FormData === "undefined") {
                    // Redirect to an empty page is required for IE < 10
                    redirect = YouLi.Common.S3FileUpload.SuccessURL;
                }

                var dataString = new FormData();

                var selectedFile = element.files[0];

                YouLi.Common.S3FileUpload.NewFileKey = YouLi.Common.S3FileUpload.KeyPrefix + selectedFile.name;

                dataString.append("key", YouLi.Common.S3FileUpload.NewFileKey);
                dataString.append("acl", YouLi.Common.S3FileUpload.ACL);
                dataString.append("Content-Type", YouLi.Common.Utils.MimeType(YouLi.Common.Utils.GetFileExtension(selectedFile.name)));
                dataString.append("x-amz-meta-uuid", YouLi.Common.S3FileUpload.Uuid);
                dataString.append("AWSAccessKeyId", YouLi.Common.S3FileUpload.AccessKey);
                dataString.append("Policy", YouLi.Common.S3FileUpload.Policy);
                dataString.append("Signature", YouLi.Common.S3FileUpload.PolicySignature);
                dataString.append("success_action_redirect", redirect);
                dataString.append('file', selectedFile);

                if (element.dataset.uploadType != "avatar") {
                    if (listElement !== undefined) {
                        $(listElement).find("#FileProgress").css("width", '0%').attr('aria-valuenow', 0);
                        $(listElement).find(".progress").removeClass("hidden");
                    }
                    else {
                        $("#FileProgress").css("width", '0%').attr('aria-valuenow', 0);
                        $(".progress").removeClass("hidden");
                    }
                }

                $.ajax({
                    url: YouLi.Common.S3FileUpload.UploadUrl,
                    type: 'POST',
                    xhr: function () {  // Custom XMLHttpRequest
                        var myXhr = $.ajaxSettings.xhr();
                        if (myXhr.upload) { // Check if upload property exists
                            myXhr.upload.addEventListener('progress', (function (passedInElement) {
                                return function (e) { overrideProgressHandler !== undefined ? overrideProgressHandler(e, passedInElement) : progressHandler(e, passedInElement); };
                            })(listElement), false); // For handling the progress of the upload
                        }
                        return myXhr;
                    },
                    //Ajax events
                    success: function (data, textStatus, xhr) {
                        if (overrideSuccessHandler !== undefined) {
                            eval(overrideSuccessHandler(data, textStatus, xhr, listElement));
                        }
                        else {
                            successHandler(data, textStatus, xhr);
                        }
                    },
                    error: overrideErrorHandler !== undefined ? overrideErrorHandler : errorHandler,
                    complete: function (data, textStatus, xhr) {
                        if (overrideCompleteHandler !== undefined) {
                            eval(overrideCompleteHandler(data, textStatus, xhr, listElement));
                        }
                        else {
                            completeHandler(data, textStatus, xhr);
                        }
                    },
                    // Form data
                    data: dataString,
                    //Options to tell jQuery not to process data or worry about content-type.
                    cache: false,
                    contentType: false,
                    processData: false
                }).addEventListener;
            }

            progressHandler = function (e) {
                if (e.lengthComputable) {
                    var percentComplete = Math.round(e.loaded * 100 / e.total);
                    $("#FileProgress").css("width", percentComplete + '%').attr('aria-valuenow', percentComplete);
                    $('#FileProgress span').text(percentComplete + "%");
                }
                else {
                    $('#FileProgress span').text('unable to compute');
                }
            }

            function completeHandler() {
                $(".progress").addClass("hidden");
            }

            function successHandler(data) {
                if (data && data.statusCode == 200) {
                    alert("Success");
                }
            }

            function errorHandler(xhr, ajaxOptions, thrownError) {
                alert("There was an error attempting to upload the file. (" + thrownError + ")");
            }

        }(YouLi.Common.S3FileUpload ? YouLi.Common.S3FileUpload : {}));
    }(YouLi.Common ? YouLi.Common : {}));
}(YouLi ? YouLi : {}));